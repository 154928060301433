import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import Infobar from "./Infobar";
import InfobarMob from "./Infobar_mob";
import Navbar from "./Navbar";
import Footer from "./Footer";

const BlogDetail = () => {
    const { id } = useParams(); // Get the blog ID from the URL
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const API_BASE = "http://localhost:4000/api"; // Replace with your backend API
    const API_BASE = "https://api.sureshift.in/api";
    const navigate = useNavigate();

    // Memoize the fetchBlogDetails function
    const fetchBlogDetails = useCallback(async () => {
        setLoading(true);
        setError(null); // Reset error state
        try {
            const response = await axios.get(`${API_BASE}/blogs/${id}`);
            setBlog(response.data);
        } catch (err) {
            console.error('Error fetching blog details:', err);
            setError('Failed to load blog details. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchBlogDetails();
    }, [fetchBlogDetails]);

    if (loading) {
        return <div className="text-center mt-6">Loading blog details...</div>;
    }

    if (error) {
        return (
            <div className="text-center mt-6 text-red-500">
                {error}
                <button
                    onClick={() => navigate(-1)}
                    className="block text-blue-600 mt-4 font-semibold hover:underline"
                >
                    &larr; Go Back
                </button>
            </div>
        );
    }

    if (!blog) {
        return (
            <div className="text-center mt-6 text-red-500">
                Blog not found.
                <button
                    onClick={() => navigate(-1)}
                    className="block text-blue-600 mt-4 font-semibold hover:underline"
                >
                    &larr; Go Back
                </button>
            </div>
        );
    }

    return (
        <>
            <Infobar />
            <InfobarMob />
            <Navbar />
            <div className="bg-gray-100 p-6 min-h-screen lg:-mb-[120px] -mb-[50px]">
                <h1 className="text-3xl font-bold text-center mb-8">Blog Details</h1>
                <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-6">
                    <button
                        onClick={() => navigate(-1)}
                        className="text-blue-600 font-semibold mb-4 hover:underline"
                    >
                        &larr; Back to Blogs
                    </button>
                    <h1 className="text-4xl font-bold mb-4 text-[#0E2334]">{blog.title}</h1>
                    <p className="text-gray-500 text-sm mb-4">
                        <strong>By:</strong> <span className="text-[#4169E1] font-medium">{blog.author_name} </span> |{' '}
                        <strong>{new Date(blog.created_at).toLocaleDateString()} at{' '}
                            {new Date(blog.created_at).toLocaleTimeString()}</strong>
                    </p>
                    {blog.image ? (
                        <img
                            src={`https://api.sureshift.in/uploads/${blog.image}`}
                            alt={blog.title}
                            className="w-full max-h-[600px] object-fill rounded-lg mb-6"
                        />
                    ) : (
                        <div className="w-full max-h-[500px] bg-gray-200 flex items-center justify-center text-gray-500 rounded-lg mb-6">
                            No Image Available
                        </div>
                    )}
                    <div
                        className="text-gray-700 text-lg leading-relaxed"
                        dangerouslySetInnerHTML={{ __html: blog.content }}
                    />
                    <p
                        className={`mt-6 text-sm font-semibold ${blog.status === 'published' ? 'text-green-600' : 'text-yellow-600'
                            }`}
                    >
                        Status: {blog.status}
                    </p>
                    <p className="text-sm mt-4">
                        <strong>Tags:</strong> {blog.tags || 'No Tags'}
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default BlogDetail;
