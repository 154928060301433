import React, { useState } from 'react';
import axios from 'axios';

import Infobar from "./Infobar";
import InfobarMob from "./Infobar_mob";
import Navbar from "./Navbar";
import Footer from "./Footer";


const options = [
    { id: 'household', label: 'Household Moving', icon: '🚚' },
    { id: 'car', label: 'Car Moving', icon: '🚗' },
    { id: 'secure', label: 'Secure Storage', icon: '🏠' },
    { id: 'international', label: 'International Moving', icon: '🌍' },
    { id: 'office', label: 'Office Shifting', icon: '🏢' },
    { id: 'commercial', label: 'Commercial Moving', icon: '🏭' },
    { id: 'bike', label: 'Bike Moving', icon: '🏍️' },
    { id: 'fine_arts', label: 'Fine Arts & Sculptures Moving', icon: '🎨' },
    { id: 'truck', label: 'Truck Rental', icon: '🚛' },
    { id: 'last_mile', label: 'Last Mile Delivery', icon: '🚚' },
    { id: 'courier', label: 'Courier', icon: '📦' },
    { id: 'odc_consignment', label: 'ODC Consignment', icon: '🚜' },
];


const UserForm = () => {
    const [selectedOption, setSelectedOption] = useState('car');

    const handleOptionClick = (id) => {
        setSelectedOption(id);
    };

    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        pickup_date: '',
        pickup_time: '',
        pickup_address: '',
        drop_address: '',
        purpose: '' // Add purpose to initial form data
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Start loading

        const updatedFormData = {
            ...formData,
            purpose: options.find(option => option.id === selectedOption)?.label || ''
        };

        try {
            await axios.post('/user', updatedFormData);
            alert('User data submitted successfully');
            handleReset(); // Reset the form data after successful submission
        } catch (error) {
            console.error('Error submitting user data:', error);
            alert('Failed to submit user data');
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const handleReset = () => {
        setFormData(initialFormData);
    };


    const renderForm = () => {
        const commonFields = (
            <>
                <div className="flex flex-wrap mb-4">
                    <div className="w-full md:w-1/2 lg:pr-2 mb-4">
                        <label className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>
                    <div className="w-full md:w-1/2 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Phone</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-4">
                    <div className="w-full md:w-1/2 lg:pr-2 mb-4">
                        <label className="block text-gray-700">Email</label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>
                    <div className="w-full md:w-1/2 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Pickup Address</label>
                        <input
                            type="text"
                            name="pickup_address"
                            value={formData.pickup_address}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-4">
                    <div className="w-full md:w-1/2 lg:pr-2 mb-4">
                        <label className="block text-gray-700">Drop Address</label>
                        <input
                            type="text"
                            name="drop_address"
                            value={formData.drop_address}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>
                    <div className="w-full md:w-1/2 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Pickup Date</label>
                        <input
                            type="date"
                            name="pickup_date"
                            value={formData.pickup_date}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-4">
                    <div className="w-full md:w-1/2 lg:pr-2 mb-4">
                        <label className="block text-gray-700">Pickup Time</label>
                        <input
                            type="time"
                            name="pickup_time"
                            value={formData.pickup_time}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className={`w-full p-2 rounded-md mb-5 ${isLoading ? 'bg-gray-500' : 'bg-blue-500'} text-white`}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Submitting...' : 'Submit'}
                    </button>
                    <button
                        type="button"
                        onClick={handleReset}
                        className="w-full bg-red-500 text-white p-2 rounded-md"
                        disabled={isLoading}
                    >
                        Reset
                    </button>
                </div>
            </>
        )

        switch (selectedOption) {
            case 'household':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>Household Moving</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );
            case 'car':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>Car Moving</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );

            case 'secure':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>Secure Moving</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );

            case 'international':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>International Moving</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );
            case 'office':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>Office Moving</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );
            case 'commercial':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>Commerical Moving</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );
            case 'bike':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>Bike Moving</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );
            case 'fine_arts':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>Fine Arts Moving</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );
            case 'truck':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>Truck Moving</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );
            case 'last_mile':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>Last Mile Moving</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );
            case 'courier':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>Courier</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );
            case 'odc_consignment':
                return (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-6 text-center">
                            Get a free <span className='text-[red]'>ODC Consignment</span> quote
                        </h2>
                        {commonFields}
                    </form>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Infobar />
            <InfobarMob />
            <Navbar />

            <div className="w-full flex flex-col items-center lg:mt-[50px] mb-[100px]">
                <div className="w-[85%] lg:w-[60%] flex flex-wrap lg:flex-row flex-row justify-center mb-6">
                    {options.map((option) => (
                        <div
                            key={option.id}
                            onClick={() => handleOptionClick(option.id)}
                            className={`cursor-pointer p-4 lg:w-[120px] w-[90px] flex flex-col text-center items-center mb-2  justify-center rounded-md shadow-md me-2 border ${selectedOption === option.id ? 'bg-red-100 border-red-500' : 'bg-white border-gray-300'}`}
                        >
                            <div className="text-2xl mb-2">{option.icon}</div>
                            <div className={`text-xs lg:text-base font-bold ${selectedOption === option.id ? 'text-red-500' : 'text-gray-700'}`}>
                                {option.label}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-[80%] lg:w-[50%] p-6 rounded-md shadow-md bg-white ">
                    {renderForm()}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default UserForm;

