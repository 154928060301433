import React from "react";

const WhatsAppButton = () => {
  const phoneNumber = "919073291732"; // Remove the '+' sign for the URL
  const message = "Hello, Sure Shift!"; // Default message

  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <a
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-6 right-4 w-14 h-14 flex items-center justify-center bg-green-500 text-white rounded-full shadow-lg transition duration-300 hover:bg-green-600"
      aria-label="Chat with us on WhatsApp"
    >
      <i className="fab fa-whatsapp text-2xl"></i>
    </a>
  );
};

export default WhatsAppButton;
