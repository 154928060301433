import React, { useState, useEffect, useRef } from 'react';
import WhatsAppButton from "./WhatsAppButton"; 
import { useNavigate } from 'react-router-dom';
import banner from './img/banner_img_1.svg'
import Delivery_Man from './img/Delivery_Man.svg'
import Faster from './img/Faster.svg'
import Best_Courier from './img/Best_Courier.svg'
import Best_Price from './img/Best_Price.svg'
import Top_Rated from './img/Top_Rated.svg'
import Courier_Man from './img/courier_man.webp'

import Infobar from "./Infobar";
import InfobarMob from "./Infobar_mob";
import Navbar from "./Navbar";
import Footer from "./Footer";
import UserForm2 from "./UserForm2";

import Typed from 'typed.js';
import abhishek from './img/abhishek.png';
import kapil from './img/kapil.png';
import amit from './img/amit.png';
import ATHER from './img/ather energy.png';
import SBI from './img/SBI BANK.png';
import PSB from './img/panjab_sindh_bank.png';
import IBA from './img/iba.svg';
import ISO from './img/iso.svg';
import Convenience from './img/Convenience.jpeg';
import Packing from './img/Deliver.jpeg';
import Damage from './img/Damage.jpeg';
import movers_packers from './img/movers_packers.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faMapMarkerAlt, faGlobe, faBuilding, faTruckMoving, faUsers, faTruck, faMapMarkedAlt, faLightbulb, faWarehouse, faSearchLocation, faClipboardCheck, faUser, faCertificate, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const testimonials = [
  {
    text: "The team exceeded expectations with professionalism and attention to detail, completing our project on time and with exceptional quality.",
    name: "Abhishek Gupta",
    role: "IT Company",
    image: abhishek,
    rating: 5
  },
  {
    text: "Working with this team was a fantastic experience. They were responsive, creative, and truly understood our vision.",
    name: "Amit Kumar",
    role: "FOOD SHOP",
    image: amit,
    rating: 5
  },
  {
    text: "I highly recommend their services. They delivered exactly what we needed and provided excellent support throughout the process.",
    name: "Kapil Kumar",
    role: "Manufacturing Company",
    image: kapil,
    rating: 5
  },

];

const services = [
  { icon: faShieldAlt, text: "15+ Years of Trust" },
  { icon: faMapMarkerAlt, text: "664+ Service Locations" },
  { icon: faGlobe, text: "88 Worldwide Coverage" },
  { icon: faBuilding, text: "75+ Branches PAN India" },
  { icon: faTruckMoving, text: "65070+ Moves Annually" },
  { icon: faUsers, text: "Trained Manpower" },
  { icon: faTruck, text: "1500+ Containerized Trucks" },
  { icon: faMapMarkedAlt, text: "600+ GPS Enabled Vehicles" },
  { icon: faLightbulb, text: "Innovative Technology" },
  { icon: faWarehouse, text: "10 Lac sq. ft warehouse space" },
  { icon: faSearchLocation, text: "Online Consignment Tracking" },
  { icon: faClipboardCheck, text: "Free Pre-Move Survey" },
  { icon: faUser, text: "Dedicated Move Manager" },
  { icon: faCertificate, text: "IBA Approved" },
  { icon: faCheckCircle, text: "ISO Certified" },
];


const features = [
  { image: Convenience, label: 'Convenience' },
  { image: Damage, label: 'Zero Damage' },
  { image: movers_packers, label: 'Safety and Security' },
  { image: Packing, label: 'Customized Packing' },
];

export default function Home() {
  const [ssonNumber, setSSONNumber] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSSONNumber(e.target.value);
  };

  const openModal1 = (ssonNumber) => {
    if (!ssonNumber) {
      alert('Please enter a valid AWB number');
      return;
    }

    // Validate that the AWB number starts with "SSON" and is exactly 20 characters long
    const awbPattern = /^SSON[0-9A-Za-z]{16}$/;
    if (!awbPattern.test(ssonNumber)) {
      alert('Invalid AWB number format. The AWB number should start with "SSON" and be 20 characters long.');
      return;
    }

    // If the AWB number is valid, proceed with the redirection
    console.log(`AWB Number: ${ssonNumber}`);
    navigate(`/order_track?awb=${ssonNumber}`);
  };

  // This is for text animation 
  const typedElement = useRef(null);
  useEffect(() => {
    const typed = new Typed(typedElement.current, {
      strings: ['Moving', 'Relocating', 'Shifting', 'Settling'],
      typeSpeed: 150,
      backSpeed: 50,    // Speed at which the text is deleted
      loop: true        // Loop the typing effect indefinitely
    });

    // Cleanup on unmount
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div >
      <Infobar />
      <InfobarMob />
      <Navbar />
      <WhatsAppButton/>
      <div className='w-[100%] flex justify-center mt-6'>
        <div className="container mx-auto flex flex-col lg:flex-row items-center lg:space-x-12 lg:w-[80%]">
          <div className="lg:w-1/2 text-center lg:text-left w-[350px]">
            <h1 className="lg:text-6xl text-3xl font-bold mb-4">Your Trusted Partner for  <h1><span ref={typedElement} className='text-[#D81F47]'></span></h1> </h1>
            <p className="text-lg mb-6 text-[#7f7e7e] font-medium">Relocating? Let us take the load off your shoulders. Whether it’s across the street or across the country, our expert team ensures a smooth, stress-free move every time.</p>
            <a href="/#contact" className="inline-block bg-[#D81F47] text-white py-3 px-8 rounded-lg" >Book Now</a>
          </div>
          <div className="lg:w-[800px] flex justify-center lg:justify-end mt-2">
            <img src={banner} alt="Delivery Service" className="w-full lg:w-[]" />
          </div>
        </div>
      </div>

      {/* <!-- Features Section --> */}
      <div className='w-[100%] flex justify-center mt-6'>
        <section className="bg-white py-12 w-[80%]">
          <div className="container mx-auto flex flex-wrap justify-between">
            <div className="w-full md:w-1/4 p-2">
              <div className="shadow-2xl border-none p-6 border rounded-xl">
                <div className='flex justify-evenly lg:w-[50%] w-[20%]'>
                  <img src={Faster} alt="Delivery Service" className="relative w-full lg:w-1/3" />
                  <h2 className="text-lg lg:text-sm font-bold mb-4 mt-[15px] ml-[15px]">FASTER</h2>
                </div>
                <p className='lg:text-sm text-[#7f7e7e] text-xs lg:ml-[65px] ml-[60px] font-medium'>Quick, reliable, and timely moves.</p>
              </div>
            </div>
            {/* <div className="w-full md:w-1/3 p-2">
              <div className="shadow-2xl border-none p-6 border rounded-xl">
                <div className='flex justify-evenly lg:w-[65%] w-[65%]'>
                  <img src={Best_Courier} alt="Delivery Service" className="relative w-full lg:w-[50px] w-[50px]" />
                  <h2 className="text-lg lg:text-lg font-bold mb-4 mt-[15px] lg:ml-[15px] ml-[20px]">BEST COURIER</h2>
                </div>
                <p className='lg:text-base text-[#7f7e7e] text-xs lg:ml-[95px] ml-[80px] font-medium'>Lorem ipsum dolor sit amet</p>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-2">
              <div className="shadow-2xl border-none p-6 border rounded-xl">
                <div className='flex justify-evenly lg:w-[60%] w-[60%]'>
                  <img src={Best_Price} alt="Delivery Service" className="relative w-full lg:w-[50px] w-[50px]" />
                  <h2 className="text-lg lg:text-lg font-bold mb-4 mt-[15px] lg:ml-[10px] ml-[18px]">BEST PRICE</h2>
                </div>
                <p className='lg:text-base text-[#7f7e7e] text-xs lg:ml-[100px] ml-[82px] font-medium'>Lorem ipsum dolor sit amet</p>
              </div>
            </div> */}
            <div className="w-full md:w-1/4 p-2">
              <div className="shadow-2xl border-none p-6 border rounded-xl">
                <div className='flex justify-evenly lg:w-[65%] w-[100%]'>
                  <img src={Best_Courier} alt="Delivery Service" className="relative lg:w-[30px] w-[52px]" />
                  <h2 className="text-lg lg:text-sm font-bold mb-4 mt-[15px] lg:ml-[20px] ml-[20px] w-[100%]">BEST COURIER</h2>
                </div>
                <p className='lg:text-sm text-[#7f7e7e] text-xs  lg:ml-[50px] ml-[70px] font-medium'>Top-rated, secure, trusted delivery.</p>
              </div>
            </div>
            <div className="w-full md:w-1/4 p-2">
              <div className="shadow-2xl border-none p-6 border rounded-xl">
                <div className='flex justify-evenly lg:w-[60%] w-[100%] '>
                  <img src={Best_Price} alt="Delivery Service" className="relative lg:w-[30px] w-[52px]" />
                  <h2 className="text-lg lg:text-sm font-bold mb-4 mt-[15px] ml-[20px] w-[100%]">BEST PRICE</h2>
                </div>
                <p className='lg:text-sm text-[#7f7e7e] text-xs lg:ml-[50px] ml-[70px] font-medium'>Affordable, high-quality moving services.</p>
              </div>
            </div>
            <div className="w-full md:w-1/4 p-2">
              <div className="shadow-2xl border-none p-6 border rounded-xl">
                <div className='flex justify-evenly lg:w-[60%] w-[100%] '>
                  <img src={Top_Rated} alt="Delivery Service" className="relative lg:w-[30px] w-[52px]" />
                  <h2 className="text-lg lg:text-sm font-bold mb-4 mt-[15px] ml-[20px] w-[100%]">Top Rated</h2>
                </div>
                <p className='lg:text-sm text-[#7f7e7e] text-xs lg:ml-[50px] ml-[70px] font-medium'>Affordable, high-quality moving services.</p>
              </div>
            </div>

          </div>
        </section>
      </div>

      <div id='contact'><UserForm2 /></div>

      <div className="w-[100%] flex justify-center mt-[80px] lg:mt-[100px]">
        <div className="lg:w-[80%] px-4 sm:px-6 flex flex-col lg:flex-col justify-center items-center">
          <div className="lg:text-center text-center flex flex-col lg:flex-col justify-center lg:w-[55%]">
            <h2 className="lg:text-4xl text-3xl font-bold mb-3">
              Why Choose <span className="text-[#D81F47]">Sure Shift?</span>
            </h2>
          </div>
          <div className="flex flex-wrap flex-row justify-center mx-auto gap-2 p-4">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md px-2 py-4 lg:p-6 flex flex-wrap flex-col items-center lg:w-[200px] w-[90px]"
              >
                {/* Conditionally render image for IBA and ISO, otherwise render icon */}
                {service.text === "IBA Approved" || service.text === "ISO Certified" ? (
                  <img
                    src={service.text === "IBA Approved" ? IBA : ISO}
                    alt={service.text}
                    className="lg:mb-2 mb-3 lg:w-[50px] w-[30px] lg:h-[50px] h-[30px] lg:-mt-2" // Adjust size as needed
                  />
                ) : (
                  <div className="text-red-500 mb-4">
                    <FontAwesomeIcon icon={service.icon} className="text-[18px] lg:text-[32px]" />
                  </div>
                )}

                {/* Conditionally render text with line break for ISO Certified */}
                <div
                  className={`text-center font-semibold text-gray-700 ${service.text === "IBA Approved" || service.text === "ISO Certified"
                    ? "lg:text-sm text-xs text-[#D81F47]" // Custom styles for IBA and ISO
                    : "lg:text-sm text-xs" // Default styles for others
                    }`}
                >
                  {service.text === "ISO Certified" ? (
                    <>
                      ISO
                      <br />
                      Certified
                    </>
                  ) : service.text === "IBA Approved" ? (
                    <>
                      IBA
                      <br />
                      Approved Bilty
                    </>
                  ) : (
                    service.text
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-[100%] flex flex-col justify-center items-center mt-[80px] lg:mt-[100px] ">
        <div className="lg:w-[80%] px-4 sm:px-6 flex flex-col lg:flex-col justify-center items-center">
          {/* Company Information */}
          <div className="text-center mb-8 lg:mb-[50px]">
            <h1 className="lg:text-4xl text-3xl font-bold"><span className='text-[#D81F47]'>SURE SHIFT</span> RELOCATION SERVICES LTD.</h1>
            <p className="text-sm font-semibold text-gray-600">An ISO 9001:2015 & ISO 39001:2012 Certified Company</p>
            <p className="text-lg font-bold text-red-600 mt-2">India’s Largest and Most Awarded Movers</p>
            <p className="lg:text-lg text-base mt-4 max-w-2xl">
              Surshift Relocation Services Ltd is a globally recognized logistics company operating since 1987 and is recognized for imparting excellent services in packing and moving segments. We have designed our services proficiently to meet the maximum customer satisfaction and rendered them in such a way that comes out high on the expectations of our clients.
            </p>
            <a href='/about_us' className="text-red-500 font-semibold cursor-pointer mt-4 mb-2">Read More</a>
          </div>

          {/* Features Section */}
          <div className="flex flex-wrap justify-center gap-4 w-[100%]">
            {features.map((feature, index) => (
              <div key={index} className="relative lg:flex-1 lg:w-[50%] w-[45%]">
                <img src={feature.image} alt={feature.label} className="w-full h-auto object-cover rounded-md shadow-md" />
                <div className="absolute lg:text-basic text-xs bottom-0 bg-black bg-opacity-50 text-white text-center w-full py-2 rounded-b-md">
                  {feature.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <!-- Features Section --> */}
      <div className='w-[100%] flex justify-center mt-[100px]' id='tracking'>
        <div className="py-12 bg-[#D81F47] text-white lg:w-[80%] w-[95%] rounded-xl">
          <div className="mx-auto flex flex-col lg:flex-row lg:justify-around items-center lg:space-x-12 -mb-4 lg:w-[80%] ">
            <div className="lg:w-1/2 text-center lg:text-left">
              <h1 className="text-4xl font-bold mb-4 px-4">Track Your Consignment</h1>
              <p className="mb-6 px-4">Track your shipment instantly. Enter your Consignment number for updates.</p>
            </div>
            <div className="lg:w-[650px] flex flex-col lg:flex-row justify-around lg:justify-around">
              <input
                type="text"
                className="bg-transparent p-2 pl-5 lg:pl-5 lg:w-[380px] lg:me-8 mb-4 w-[250px] border border-[white] rounded-full placeholder-white"
                placeholder="Consignment Number"
                value={ssonNumber}
                onChange={handleInputChange}
              />
              <button
                onClick={() => openModal1(ssonNumber)}
                className="m-auto mb-4 bg-[#FFFFFF] text-[#D81F47] py-2 w-[150px] lg:w-[170px] lg:py-3 lg:px-10 rounded-full mb-4 font-bold"
              >Search
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Special Occasion Section --> */}
      <div className='w-[100%] flex justify-center mt-[60px] lg:mt-[75px] '>
        <div className="relative container mx-auto flex flex-col justify-between lg:ml-8 p-2 lg:mr-5 lg:flex-row lg:space-x-12 lg:w-[80%] ">

          {/* Image for larger screens */}
          <img src={Delivery_Man} alt="Delivery Service" className="hidden lg:block lg:mt-[100px] w-[600px] lg:-ml-[100px] -ml-[45px]" />

          {/* New image for mobile view */}
          <img src={Courier_Man} alt="Mobile Delivery Service" className="block lg:hidden w-[200px] -mb-[30px] ml-[75px]" />

          <div className="lg:w-[60%] text-center text-white lg:text-left mt-8 lg:mt-[28.5px] bg-[#0C1E2D] lg:p-[50px] p-[25px] lg:py-[100px] lg:ps-[80px] rounded-xl lg:h-[480px]" >
            <h2 className="text-4xl font-bold mb-4">Reliable and Efficient Moving Services</h2>
            <p className="text-lg mb-6">Our team of experienced professionals is dedicated to providing seamless moving solutions tailored to your specific needs. Whether you're relocating your home or office, we handle every aspect of the process with care and efficiency.</p>
            <a href="/#contact" className="inline-block bg-[#D81F47] text-white py-3 px-8 rounded-full">Book Now</a>
          </div>
        </div>
      </div>


      {/* <!-- Testinomial --> */}
      <div className="w-[100%] flex justify-center mt-[80px] lg:mt-[100px]">
        <div className="lg:w-[80%] mx-auto px-4 sm:px-6 flex flex-col lg:flex-col justify-center items-center">
          <div className="lg:text-center text-center flex flex-col lg:flex-col justify-center lg:w-[55%] ">
            <h2 className="lg:text-4xl text-3xl font-bold mb-3">WHAT THEY SAY ABOUT US</h2>
            <p className="text-lg text-[#7f7e7e] font-normal">Read what our satisfied clients have to say about our services.</p>
          </div>

          <div className="mt-10 lg:mt-[60px]">
            <div className="flex flex-wrap -mx-4 ">
              {testimonials.map((testimonial, index) => (
                <div key={index} className="w-full md:w-1/3 px-4 mb-8">
                  <div className="bg-white p-6 rounded-lg shadow-md text-center">
                    <p className="text-gray-800 mb-6 font-bold text-lg">{testimonial.text}</p>
                    <img
                      className="w-16 h-16 rounded-full mx-auto mb-4"
                      src={testimonial.image}
                      alt={testimonial.name}
                    />
                    <h5 className="text-xl font-semibold font-bold">{testimonial.name}</h5>
                    <p className="text-gray-400 font-bold">{testimonial.role}</p>
                    <div className="mt-4">
                      {Array.from({ length: testimonial.rating }, (_, i) => (
                        <svg
                          key={i}
                          className="w-6 h-6 inline-block text-yellow-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.966a1 1 0 00.95.69h4.184c.969 0 1.371 1.24.588 1.81l-3.389 2.462a1 1 0 00-.364 1.118l1.286 3.965c.3.921-.755 1.688-1.538 1.118l-3.389-2.462a1 1 0 00-1.175 0l-3.389 2.462c-.783.57-1.838-.197-1.538-1.118l1.286-3.965a1 1 0 00-.364-1.118L2.69 9.393c-.783-.57-.381-1.81.588-1.81h4.184a1 1 0 00.95-.69l1.286-3.966z" />
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Partner Section --> */}
      <div className="w-[100%] flex justify-center lg:mt-[80px] mt-[60px]">
        {/* <div className="bg-white py-12"> */}
        <div className="w-[80%] max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex lg:flex-row flex-col justify-center items-center">
          <div className='lg:w-[450px] text-center lg:text-left'>
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">OUR CLIENTS</h2>
            <p className="mt-4 max-w-2xl text-xl lg:mx-auto text-[#7f7e7e] font-normal">
              We are honored to work alongside esteemed partners who share our commitment to excellence and innovation in the industry.
            </p>
          </div>
          <div className="mt-10 flex flex-wrap justify-center lg:ml-[20px]">
            <div className="flex justify-center items-center p-4">
              <img className="lg:h-16" src={ATHER} alt="Ather Energy" />
            </div>
            <div className="flex justify-center items-center p-4">
              <img className="lg:h-16" src={SBI} alt="State Bank Of India" />
            </div>
            <div className="flex justify-center items-center p-4">
              <img className="lg:w-[250px] h-16" src={PSB} alt="Panjab and Sindh Bank" />
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      {/* <!-- Features Section --> */}
      <div className='w-[100%] flex justify-center mt-[80px] lg:mt-[120px]'>
        <div className="py-5 p-[5px] lg:py-[50px] bg-[#D81F47] text-white lg:w-[80%] w-[95%] rounded-xl">
          <div className="mx-auto flex flex-col lg:flex-row lg:justify-between items-center lg:space-x-12 -mb-4 lg:w-[80%]">
            <div className="lg:w-1/2 text-center lg:text-left">
              <h1 className="text-3xl lg:text-4xl font-bold mb-4 px-4">LET'S JOIN US</h1>
              <p className="text-sm lg:text-lg mb-6 px-4">Ready to take the next step in your career? Join us for growth, opportunities, and a supportive work environment. Be a part of our journey to excellence.</p>
            </div>
            <a href="/#contact" className=" text-center text-sm lg:text-lg bg-[#FFFFFF] text-[#D81F47] py-2 w-[120px] lg:w-[150px] lg:py-4 py-3 rounded-full font-bold lg:mb-[50px] mb-[18px]">JOIN NOW</a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
