import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from './context/AuthContext';
import JoditEditor from 'jodit-react'; // Import JoditEditor

const AdminBlogDashboard = () => {
    const [blogs, setBlogs] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [currentBlog, setCurrentBlog] = useState({
        title: '',
        content: '',
        author_name: '',
        tags: '',
        status: 'draft',
        image: null,
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const { logout } = useContext(AuthContext);

    // const API_BASE = "http://localhost:4000/api";
    const API_BASE = "https://api.sureshift.in/api";

    const editorRef = useRef(null); // Reference for JoditEditor

    useEffect(() => {
        fetchBlogs();
    }, []);

    useEffect(() => {
        const filtered = blogs.filter((blog) =>
            blog.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredBlogs(filtered);
    }, [searchQuery, blogs]);

    const fetchBlogs = async () => {
        try {
            const response = await axios.get(`${API_BASE}/blogs`);
            setBlogs(response.data);
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };

    const handleAddOrEditBlog = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', currentBlog.title);
        formData.append('content', currentBlog.content);
        formData.append('author_name', currentBlog.author_name);
        formData.append('tags', currentBlog.tags);
        formData.append('status', currentBlog.status);
        if (currentBlog.image) formData.append('image', currentBlog.image);

        try {
            if (isEditing) {
                await axios.put(`${API_BASE}/blogs/${currentBlog.id}`, formData);
                alert('Blog updated successfully!');
            } else {
                await axios.post(`${API_BASE}/blogs`, formData);
                alert('Blog added successfully!');
            }
            fetchBlogs();
            closeModal();
        } catch (error) {
            console.error('Error adding/editing blog:', error);
        }
    };

    const handleDeleteBlog = async (id) => {
        try {
            await axios.delete(`${API_BASE}/blogs/${id}`);
            alert('Blog deleted successfully!');
            fetchBlogs();
        } catch (error) {
            console.error('Error deleting blog:', error);
        }
    };

    const openModal = (blog = null) => {
        if (blog) {
            setCurrentBlog(blog);
            setIsEditing(true);
        } else {
            setCurrentBlog({
                title: '',
                content: '',
                author_name: '',
                tags: '',
                status: 'draft',
                image: null,
            });
            setIsEditing(false);
        }
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setCurrentBlog({
            title: '',
            content: '',
            author_name: '',
            tags: '',
            status: 'draft',
            image: null,
        });
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-2xl font-bold text-center mb-6">Admin Blog Dashboard</h1>
            {/* Logout and Back Buttons */}
            <div className='flex justify-center items-center lg:w-[100%]'>
                <div className="w-[165px] px-2 pb-3 pt-2 mx-2 text-[#d81f47] border-[1px] border-[#d81f47] rounded-full flex items-center justify-center mb-[20px]">
                    <button className="px-5 font-medium" onClick={logout}>Admin Logout</button>
                </div>
                <div className="w-[210px] px-2 pb-3 pt-2 mx-2 text-[#d81f47] border-[1px] border-[#d81f47] rounded-full flex items-center justify-center mb-[20px]">
                    <a href="/admin-dashboard" ><button className="px-5 font-medium">Back to Admin Page</button></a>
                </div>
            </div>
            <div className="flex justify-between mb-4">
                <input
                    type="text"
                    placeholder="Search by Title"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="p-2 border rounded-md w-1/3"
                />
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={() => openModal()}
                >
                    Add New Blog
                </button>
            </div>

            {/* Blogs Table */}
            <table className="min-w-full bg-white border rounded-md">
                <thead>
                    <tr className="border-b">
                        <th className="px-4 py-2 text-left">Title</th>
                        <th className="px-4 py-2 text-left">Author</th>
                        <th className="px-4 py-2 text-left">Tags</th>
                        <th className="px-4 py-2 text-left">Status</th>
                        <th className="px-4 py-2 text-left">Image</th>
                        <th className="px-4 py-2 text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredBlogs.map((blog) => (
                        <tr key={blog.id} className="border-b hover:bg-gray-50">
                            <td className="px-4 py-2">{blog.title}</td>
                            <td className="px-4 py-2">{blog.author_name}</td>
                            <td className="px-4 py-2">{blog.tags}</td>
                            <td className="px-4 py-2">{blog.status}</td>
                            <td className="px-4 py-2">
                                {blog.image && (
                                    <img
                                        // src={`http://localhost:4000/uploads/${blog.image}`}
                                        src={`https://api.sureshift.in/uploads/${blog.image}`}
                                        alt="Blog"
                                        className="w-16 h-16 rounded"
                                    />
                                )}
                            </td>
                            <td className="px-4 py-2 text-center space-x-2">
                                <button
                                    onClick={() => openModal(blog)}
                                    className="bg-yellow-500 text-white px-4 py-1 rounded-md"
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={() => handleDeleteBlog(blog.id)}
                                    className="bg-red-500 text-white px-4 py-1 rounded-md"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Adding/Editing Blogs */}
            {modalVisible && (
                <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-md shadow-lg w-1/2" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                        <h2 className="text-xl font-bold mb-4">
                            {isEditing ? 'Edit Blog' : 'Add Blog'}
                        </h2>
                        <form onSubmit={handleAddOrEditBlog}>
                            <div className="mb-4">
                                <label className="block mb-2 font-medium">Title</label>
                                <input
                                    type="text"
                                    value={currentBlog.title}
                                    onChange={(e) =>
                                        setCurrentBlog({ ...currentBlog, title: e.target.value })
                                    }
                                    required
                                    className="p-2 border rounded-md w-full"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2 font-medium">Author</label>
                                <input
                                    type="text"
                                    value={currentBlog.author_name}
                                    onChange={(e) =>
                                        setCurrentBlog({ ...currentBlog, author_name: e.target.value })
                                    }
                                    required
                                    className="p-2 border rounded-md w-full"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2 font-medium">Content</label>
                                <div className="h-50 overflow-y-auto">
                                    <JoditEditor
                                        value={currentBlog.content}
                                        onChange={(newContent) => {
                                            setCurrentBlog({ ...currentBlog, content: newContent });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2 font-medium">Tags</label>
                                <input
                                    type="text"
                                    value={currentBlog.tags}
                                    onChange={(e) =>
                                        setCurrentBlog({ ...currentBlog, tags: e.target.value })
                                    }
                                    className="p-2 border rounded-md w-full"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2 font-medium">Status</label>
                                <select
                                    value={currentBlog.status}
                                    onChange={(e) =>
                                        setCurrentBlog({ ...currentBlog, status: e.target.value })
                                    }
                                    className="p-2 border rounded-md w-full"
                                >
                                    <option value="draft">Draft</option>
                                    <option value="published">Published</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2 font-medium">Image</label>
                                <input
                                    type="file"
                                    onChange={(e) =>
                                        setCurrentBlog({
                                            ...currentBlog,
                                            image: e.target.files[0],
                                        })
                                    }
                                    className="p-2 border rounded-md w-full"
                                />
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    onClick={closeModal}
                                    className="bg-gray-500 text-white px-4 py-2 rounded-md"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

        </div>
    );
};

export default AdminBlogDashboard;
