// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jodit-wysiwyg a {
    color: blue !important;
    text-decoration: underline !important;
}
.jodit-wysiwyg ul {
    list-style-type: disc !important; /* Ensure bullets are visible */
    margin-left: 20px; /* Add indentation for better readability */
}
.jodit-wysiwyg ol {
    list-style-type: decimal !important; /* For numbered lists */
    margin-left: 20px;
}

.text-gray-700 a,.text-black-700 a {
    color: blue !important;
    text-decoration: underline !important;
}
.text-gray-700 ul,.text-black-700 ul {
    list-style-type: disc !important; /* Ensure bullets are visible */
    margin-left: 20px; /* Add indentation for better readability */
}

.text-gray-700 ol,.text-black-700 ol {
    list-style-type: decimal !important; /* For numbered lists */
    margin-left: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qCAAqC;AACzC;AACA;IACI,gCAAgC,EAAE,+BAA+B;IACjE,iBAAiB,EAAE,2CAA2C;AAClE;AACA;IACI,mCAAmC,EAAE,uBAAuB;IAC5D,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,qCAAqC;AACzC;AACA;IACI,gCAAgC,EAAE,+BAA+B;IACjE,iBAAiB,EAAE,2CAA2C;AAClE;;AAEA;IACI,mCAAmC,EAAE,uBAAuB;IAC5D,iBAAiB;AACrB","sourcesContent":[".jodit-wysiwyg a {\r\n    color: blue !important;\r\n    text-decoration: underline !important;\r\n}\r\n.jodit-wysiwyg ul {\r\n    list-style-type: disc !important; /* Ensure bullets are visible */\r\n    margin-left: 20px; /* Add indentation for better readability */\r\n}\r\n.jodit-wysiwyg ol {\r\n    list-style-type: decimal !important; /* For numbered lists */\r\n    margin-left: 20px;\r\n}\r\n\r\n.text-gray-700 a,.text-black-700 a {\r\n    color: blue !important;\r\n    text-decoration: underline !important;\r\n}\r\n.text-gray-700 ul,.text-black-700 ul {\r\n    list-style-type: disc !important; /* Ensure bullets are visible */\r\n    margin-left: 20px; /* Add indentation for better readability */\r\n}\r\n\r\n.text-gray-700 ol,.text-black-700 ol {\r\n    list-style-type: decimal !important; /* For numbered lists */\r\n    margin-left: 20px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
