import React from 'react';

const InfoBar = () => {
    return (
        <div className="bg-[#d81f47] text-white py-2 md:hidden">
            <div className="container mx-auto flex justify-between items-center px-4 sm:px-6 lg:px-8">
                <div className="flex items-center m-auto space-x-4 text-sm">
                    <a href="mailto:hello@sureshift.in" className="flex items-center space-x-1">
                        <i className="fas fa-envelope"></i>
                        <span className='font-normal'>info@sureshift.in</span>
                    </a>
                    <a href="tel:+919073291732" className="flex items-center space-x-1">
                        <i className="fas fa-phone"></i>
                        <span className='font-normal'>90 732 91 732</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default InfoBar;
