import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import Infobar from "./Infobar";
import InfobarMob from "./Infobar_mob";
import Navbar from "./Navbar";
import Footer from "./Footer";

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [visibleBlogs, setVisibleBlogs] = useState(6); // Controls the number of visible blogs
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    // const API_BASE = "http://localhost:4000/api"; // Replace with your backend API
    const API_BASE = "https://api.sureshift.in/api";

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const response = await axios.get(`${API_BASE}/blogs`);
            setBlogs(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };

    const loadMoreBlogs = () => {
        setVisibleBlogs((prevVisible) => prevVisible + 6);
    };

    // Filter blogs based on search query and status
    const filteredBlogs = blogs.filter(
        (blog) =>
            blog.status === 'published' && // Ensure only published blogs are displayed
            blog.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <Infobar />
            <InfobarMob />
            <Navbar />
            <div className="bg-gray-100 p-6 min-h-screen lg:-mb-[120px] -mb-[50px]">
                <h1 className="text-3xl font-bold text-center mb-8">Our Latest Blogs</h1>

                {/* Search Input */}
                <div className="flex justify-center mb-8">
                    <input
                        type="text"
                        placeholder="Search by title..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full sm:w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                </div>

                {filteredBlogs.length > 0 ? (
                    <div className="flex flex-col gap-6 lg:w-[80%] m-auto">
                        {filteredBlogs.slice(0, visibleBlogs).map((blog) => (
                            <div key={blog.id} className="bg-white rounded-lg shadow-lg overflow-hidden flex items-center p-8 border-solid border-2 border-[#0E2334]">
                                <div className="flex-1">
                                    <h2 className="text-2xl font-semibold mb-3 text-[#D81F47]">{blog.title}</h2>
                                    <p className="text-sm text-black-500 mb-2 ">
                                        <strong>By:</strong> <span className="text-[#4169E1] font-medium">{blog.author_name} </span>
                                        <strong>
                                            | {' '}
                                            {new Date(blog.created_at).toLocaleDateString()} at{' '}
                                            {new Date(blog.created_at).toLocaleTimeString()}
                                        </strong>
                                    </p>
                                    <p
                                        className="text-black-700 mb-4 font-medium"
                                        dangerouslySetInnerHTML={{
                                            __html: blog.content.length <= 250
                                                ? blog.content
                                                : blog.content.slice(0, 250) + '...',
                                        }}
                                    />
                                    <a
                                        href={`/blog-detail/${blog.id}`} // Dynamic link to the blog detail page
                                        className="inline-block mt-4 bg-[#D81F47] bg-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-[#af1131] hover:bg-600"
                                    >
                                        Read More
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-center text-gray-600">No blogs available.</p>
                )}

                {visibleBlogs < filteredBlogs.length && (
                    <div className="flex justify-center mt-20">
                        <button
                            onClick={loadMoreBlogs}
                            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
                        >
                            Load More Blogs
                        </button>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default BlogPage;
